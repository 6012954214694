<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      {{ $t('Brand List') }}
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="brandModuleName"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-catalog-brand-create' }"
      back-to-list-path="settings-catalog-brand-list"
      :table-config-options="{
        endpoint: `${brandModuleName}/trashList`,
        sortBy:'created_at'
      }"
    >
      <template #cell(is_active)="{data}">
        {{ getStatus(data.value).name }}
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Delete')"
            icon="LTrashIcon"
            size="16"
            class="cursor-pointer border-dotted featherIcon mr-1"
            @click="remove(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import tableConfig from '../brandConfig'

export default {
  name: 'BrandList',
  components: {
    LTable,
  },
  inject: ['brandModuleName'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters('listModule', ['getStatus']),
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.brandModuleName}/restoreFromTrashList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.brandModuleName}/del`).then(() => {
        this.refetchData()
      })
    },
  },
  setup() {
    const { tableColumns } = tableConfig()
    return {
      tableColumns,
    }
  },
}
</script>
