var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5"},[_vm._v(" "+_vm._s(_vm.$t('Brand List'))+" ")]),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.brandModuleName,"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-catalog-brand-create' },"back-to-list-path":"settings-catalog-brand-list","table-config-options":{
      endpoint: (_vm.brandModuleName + "/trashList"),
      sortBy:'created_at'
    }},scopedSlots:_vm._u([{key:"cell(is_active)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getStatus(data.value).name)+" ")]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cursor-pointer border-dotted featherIcon mr-1",attrs:{"title":_vm.$t('Delete'),"icon":"LTrashIcon","size":"16"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"featherIcon cursor-pointer border-dotted",attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"24"},on:{"click":function($event){return _vm.restoreContact(data.item.id)}}})],1)]}},{key:"filter",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }